<template>
  <v-card
    v-if="!isLoading"
    flat
    class="pa-3 mt-2"
  >
    <v-card-text class="d-flex">
      <v-avatar
        v-if="avatar === null"
        rounded
        size="120"
        class="me-6"
      >
        <v-img src="@/assets/images/avatars/1.png"></v-img>
      </v-avatar>
      <fragment v-else>
        <v-avatar
          v-if="changeAvatar"
          rounded
          size="120"
          class="me-6"
        >
          <img
            :src="previewImage === null ? rutaPublic + avatar : previewImage"
            class="rounded"
            alt="avatar"
          />
        </v-avatar>
        <v-avatar
          v-else
          rounded
          size="120"
          class="me-6"
        >
          <img
            :src="rutaPublic + avatar"
            class="rounded"
            alt="avatar"
          />
        </v-avatar>
      </fragment>

      <!-- upload photo -->
      <div>
        <v-row
          v-if="!onlyShow"
          no-gutters
        >
          <v-col
            cols="12"
            md="12"
          >
            <v-btn
              color="primary"
              class="me-3 mt-5"
              @click="activeFileUpload"
            >
              <v-icon class="d-sm-none">
                {{ icons.mdiCloudUploadOutline }}
              </v-icon>
              <span class="d-none d-sm-block">{{ $t('lbl.changeFoto') }}</span>
            </v-btn>

            <v-file-input
              id="refInputEl"
              ref="refInputEl"
              v-model="avatar"
              accept="image/*"
              outlined
              dense
              label="Avatar"
              hide-details
              :hide-spin-buttons="true"
              :hide-input="true"
              prepend-icon=""
              @change="setAvatar"
              @click:clear="clearAvatar"
            ></v-file-input>
          </v-col>
          <v-col
            cols="12"
            md="12"
          >
            <p class="text-sm mt-5">
              {{ $t('lbl.infoFoto') }}
            </p>
          </v-col>
        </v-row>
      </div>
    </v-card-text>

    <v-card-text>
      <v-form class="multi-col-validation mt-6">
        <v-row>
          <v-col
            md="3"
            cols="12"
          >
            <v-text-field
              v-model="accountData.name"
              :label="$t('lbl.name')"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            md="3"
            cols="12"
          >
            <v-text-field
              v-model="accountData.apellidos"
              :label="$t('lbl.apellidos')"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="2"
          >
            <v-select
              v-model="accountData.leng"
              outlined
              dense
              :label="$t('lbl.leng')"
              :items="languajes"
              item-text="name"
              item-value="code"
              @change="setLocale(accountData.leng)"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            md="2"
          >
            <v-select
              v-model="accountData.currency_id"
              :items="monedas"
              :label="$t('register.moneda')"
              outlined
              dense
              item-text="code"
              item-value="id"
              hide-details="auto"
              @change="setChangeCurrency()"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="accountData.email"
              :label="$t('lbl.email')"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="1"
          >
            <v-text-field
              v-model="accountData.code_phone"
              outlined
              dense
              type="number"
              prefix="+"
              :label="$t('lbl.code')"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="2"
          >
            <v-text-field
              v-model="accountData.phone"
              outlined
              dense
              type="number"
              :label="$t('lbl.telefono')"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <v-select
              v-model="accountData.role_id"
              dense
              outlined
              :label="$t('lbl.rol')"
              :items="roles"
              item-text="name"
              item-value="id"
              disabled
            ></v-select>
          </v-col>

          <!--<v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="accountData.active"
              dense
              outlined
              label="Status"
              :items="status"
            ></v-select>
          </v-col>-->

          <v-col
            v-if="accountData.company_id"
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="accountData.company.name"
              dense
              outlined
              disabled
              :label="$t('lbl.company')"
            ></v-text-field>
          </v-col>

          <!-- alert -->
          <v-col
            v-if="changeEmail"
            cols="12"
          >
            <v-alert
              color="warning"
              text
              class="mb-0"
            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{ $t('msg.profileChangeEmailTitle') }}
                  </p>
                  <!--<a
                    href="javascript:void(0)"
                    class="text-decoration-none warning--text"
                  >
                    <span class="text-sm">Resend Confirmation</span>
                  </a>-->
                </div>
              </div>
            </v-alert>
          </v-col>

          <v-col cols="12">
            <v-btn
              color="primary"
              class="me-3 mt-4"
              :loading="loading"
              @click="save"
            >
              {{ $t('btn.acceptChange') }}
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              class="mt-4"
              @click="cancele"
            >
              {{ $t('btn.cancele') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiCamera,
} from '@mdi/js'

export default {
  props: {
    accountData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: false,
      avatar: null,
      previewImage: null,
      changeAvatar: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiCamera,
      },
      roles: [],
      editUser: false,
      model: {
        avatar: null,
        id: null,
        name: null,
        apellidos: null,
        email: null,
        role_id: null,
        active: true,
      },
      password: '',
      confirmation_password: '',
      permisos: [],
      permisosItem: [],
      loading: false,
      onlyShow: false,
      rules: {
        min: v => v.length >= 6 || this.$t('rules.min6'),
      },
      show: false,
      status: [],
      emailOrigin: null,
      languajes: [],
      monedas: [],
    }
  },
  computed: {
    ...mapState({
      userUpdate: state => state.app.userUpdate,
      userUpdateAvatar: state => state.app.userUpdateAvatar,
    }),
    btnTitle() {
      return sessionStorage.getItem('user-id') === null ? this.$t('btn.send') : this.$t('btn.update')
    },
    title() {
      return sessionStorage.getItem('user-id') === null ? this.$t('btn.create') : this.$t('btn.edit')
    },
    changeEmail() {
      if (this.emailOrigin !== this.accountData.email) {
        return true
      }

      return false
    },
  },
  created() {
    this.emailOrigin = this.accountData.email
    this.avatar = this.accountData.avatar
    this.changeAvatar = false

    // this.getRoles()
    this.roles.push(this.accountData.role)
    this.languajes.push({ name: 'Español', code: 'es' })
    this.languajes.push({ name: 'English', code: 'en' })

    this.getCurrencies()
  },
  methods: {
    ...mapMutations([
      'setUserUpdate',
      'setUserUpdateAvatar',
      'setCurrencyCode',
      'setCurrencySymbol',
    ]),
    activeFileUpload() {
      const refInputEl = document.getElementById('refInputEl')
      if (refInputEl != null) {
        refInputEl.click()
      }
    },
    getRoles() {
      this.axios.get(`role?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
        },
      }).then(res => {
        this.roles = res.data.data
      })
      // eslint-disable-next-line no-return-assign
        .finally(() => this.isLoading = false)
    },
    getCurrencies() {
      // &active=1
      this.axios.get('currency_list?per_page=10000').then(res => {
        this.monedas = res.data.data
      })
    },
    setAvatar() {
      this.previewImage = URL.createObjectURL(this.avatar)
      this.changeAvatar = true
      if (this.avatar) {
        const formData = new FormData()
        if (this.avatar) {
          formData.append('avatar', this.avatar)
          this.axios
            .post('profile/change-avatar', formData, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              // eslint-disable-next-line no-empty
              if (response.data.success === false) { }
            })
        }
      }
    },
    clearAvatar() {
      this.previewImage = null
      this.changeAvatar = !this.changeAvatar
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          }
          this.avatar = response.data.data.avatar
        })
    },
    save() {
      if (this.accountData.name
          && this.accountData.apellidos
          && this.accountData.email
          && this.accountData.leng
          && this.accountData.currency_id
          && this.accountData.phone
          && this.accountData.code_phone) {
        this.loading = true
        const json = {
          name: this.accountData.name,
          apellidos: this.accountData.apellidos,
          email: this.accountData.email,
          leng: this.accountData.leng,
          currency_id: this.accountData.currency_id,
          phone: this.accountData.phone,
          code_phone: this.accountData.code_phone,
        }

        const formData = new FormData()
        formData.append('data', JSON.stringify(json))

        /* if (this.avatar) {
          formData.append('avatar', this.avatar)
          json.avatar = this.avatar
        } */

        this.axios
          .post('profile', formData, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(response => {
            // eslint-disable-next-line no-empty
            if (response.data.success === false) { } else {
              this.$toast.success(this.$t('msg.infoSuccess'))
            }
          })
        // eslint-disable-next-line no-return-assign
          .finally(() => this.loading = false)
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },

    cancele() {
      this.$router.go(-1)
    },
    setLocale(locale) {
      this.$i18n.locale = locale

      localStorage.setItem('lenguaje', locale)

      const json = {
        locale,
      }
      this.axios
        .post('profile/update-data', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) { }
        })
    },

    setChangeCurrency() {
      const currency = this.monedas.filter(e => e.id === this.accountData.currency_id)[0]
      localStorage.setItem('currency_code', currency.code)
      localStorage.setItem('currency_symbol', currency.symbol)

      this.setCurrencyCode(localStorage.getItem('currency_code'))
      this.setCurrencySymbol(localStorage.getItem('currency_symbol'))

      const json = {
        currency_id: currency.id,
        currency_code: this.currency_code,
        currency_symbol: this.currency_symbol,
      }
      this.axios
        .post('profile/update-data', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) { }
        })
    },
  },
}
</script>
